.letter-navigation-container {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px;
  padding: 6px 0;
  border-top: 1px solid rgba(0,0,0,.125);
  font-family: 'Fira Mono', monospace;
}

.fade-enter .letter-navigation-container{
  transform: translateY(200px);
}

.fade-enter.fade-enter-active .letter-navigation-container{
  transform: translateY(0);
  transition-duration: 300ms;
  transition-delay: 100ms;
}

.letter-navigation-item {
  width: 25px;
  height: 40px;
  margin-right: 4px;
  border-radius: 0;
  display: flex;
  align-items: center;
  color: grey;
}

.letter-navigation-item.is-focused{
  color: black;
}

.letter-navigation-content {
  margin: auto;
  font-size: 26px
}