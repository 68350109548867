@import url('https://fonts.googleapis.com/css?family=Fira+Mono:400,500');

@font-face {
  font-family: reproNull;
  src: url(./fonts/Repro-null.otf);
}
@font-face {
  font-family: repro100;
  src: url(./fonts/Repro-100.otf);
}
@font-face {
  font-family: repro200;
  src: url(./fonts/Repro-200.otf);
}
@font-face {
  font-family: repro300;
  src: url(./fonts/Repro-300.otf);
}
@font-face {
  font-family: repro400;
  src: url(./fonts/Repro-400.otf);
}
@font-face {
  font-family: repro500;
  src: url(./fonts/Repro-500.otf);
}
@font-face {
  font-family: repro600;
  src: url(./fonts/Repro-600.otf);
}

.repro-Null{
  font-family: reproNull;
}
.repro-100{
  font-family: repro100;
}
.repro-200{
  font-family: repro200;
}
.repro-300{
  font-family: repro300;
}
.repro-400{
  font-family: repro400;
}
.repro-500{
  font-family: repro500;
}
.repro-600{
  font-family: repro600;
}

.style-default {

}

.style-ss01 {
  -moz-font-feature-settings: "ss01";
  -webkit-font-feature-settings: "ss01";
  font-feature-settings: "ss01";
}

.style-ss02 {
  -moz-font-feature-settings: "ss02";
  -webkit-font-feature-settings: "ss02";
  font-feature-settings: "ss02";
}

body {
  font-family: myFirstFont;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: black;
}

* {
  box-sizing: border-box;
}