.slideshow-item {
  width: 100%;
  height: calc(100vh - 48px);
  margin-right: 10px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.slideshow-img {
  position: relative;
  margin: auto;
  max-height: 90%;
  max-width: 90%;
}


.fade-enter .slideshow-img{
  transform: scale(0.2);
}

.fade-enter.fade-enter-active .slideshow-img{
  transform: scale(1);
  transition-duration: 300ms;
}