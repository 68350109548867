.letter-showcase {
  width: 100%;
  height:100%;
}

.information {
  z-index: 10;
  position: absolute;
  padding: 12px 0;
  top: 12px;
  left: 12px;
  right: 12px;
  font-family: 'Fira Mono', monospace;
  color: black;
}

.badge {
  position: absolute;
  bottom: 64px;
  left:50%;
  margin-left: -28px;
  width:56px;
  padding: .25em .4em;
  font-family: 'Fira Mono', monospace;
  font-size: 16px;
  text-align: center;
  border-radius: .25rem;
  color: #fff;
  background-color: #636365;
  z-index: 20;
}

.badge:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #636365 transparent transparent transparent;
}

.badge.close{
  opacity: 0;
}

.badge.open{
  opacity: 1;
}

.fade-enter .badge{
  transform: translateY(200px);
}

.fade-enter.fade-enter-active .badge{
  transform: translateY(0);
  transition-duration: 300ms;
  transition-delay: 100ms;
}
