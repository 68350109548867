
.container {
  padding: 16px;
  max-width: 1400px;
  margin: 0px auto;
}

.about{
  border-bottom: 1px solid white;
}

.text{
  text-align: left;
  font-family: 'Fira Mono', monospace;
  hyphens: auto;
  color: white;
  font-weight: 400;
  font-size: 16px;
}

.text-small{
  margin: 40px 0 16px;
  font-size: 16px;
}

.text-small a{
  text-decoration: none;
  color: white;
}

.text-small a:hover{
  color:#636365;
}

.fontstyles-overview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (min-width: 576px) {
  .text {
    font-size: 21px;
  }
  .text-small {
    font-size: 16px;
  }
}

@media (min-width: 768px){
  .container {
    padding: 24px;
  }
  .container:after{
    content: "";
    width: calc(33% - 16px);
  }
}

@media (min-width: 1281px) {
  .text {
    font-size: 24px;
  }
  .text-small {
    font-size: 21px;
  }
}