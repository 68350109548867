.list-group {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  list-style-type: none;
  width: 100%;
  max-width: 500px;
}

.list-group-item{
  display: block;
  position: relative;
  height: 48px;
  padding: 14px;
  border-bottom: 1px solid rgba(0,0,0,.125);
  font-family: 'Fira Mono', monospace;
  color: black;
}

.list-group-item:after{
  content: "LADEN";
  position: absolute;
  top: 11px;
  right: 14px;
  padding: 4px 8px;
  background-color: #636365;
  color: white;
  font-size: 12px;
  border-radius: 16px;
  letter-spacing: 0.5px;
}

.list-group-item:hover{
  background-color:#eaebed;
}

.list-group-icon{
  float: right;
  color: #26a69a;
}