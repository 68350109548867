.speciem-showcase {
  width: 100%;
  height:100%;
}

.information {
  z-index: 10;
  position: absolute;
  padding: 12px 0;
  top: 12px;
  left: 12px;
  right: 12px;
  font-family: 'Fira Mono', monospace;
  color: black;
  /* border-bottom: 1px solid rgba(0,0,0,.125); */
}