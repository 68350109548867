.overlay-page{
  background-color: white;
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  overflow: hidden;
  z-index: 100;
}

.overlay-page.fade-enter{
  border-radius: 8px;
  top:20px;
  bottom:20px;
  left:20px;
  right:20px;
}

@media (min-width: 576px) {
  .overlay-page.fade-enter{
    border-radius: 8px;
    top:80px;
    bottom:80px;
    left:80px;
    right:80px;
  }
}

.overlay-page.fade-enter.fade-enter-active{
  border-radius: 0;
  top:0;
  bottom:0;
  left:0;
  right:0;
  transition: 200ms;
}