.button-close {
  position: absolute;
  top:12px;
  right: 12px;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  line-height: 32px;
  background-color: #636365;
  z-index: 20;
}

.button-close:before, .button-close:after {
  position: absolute;
  left: 15px;
  top: 8px;
  content: ' ';
  height: 16px;
  width: 2px;
  background-color: white;
}

.button-close:before {
  transform: rotate(45deg);
}
.button-close:after {
  transform: rotate(-45deg);
}

/*
.button-close:before {
  transform: rotate(-45deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 25px;
  margin-left: 25px;
  display: block;
  height: 50px;
  width: 50px;
  background-color: black;
}

.button-close:after {
  transform: rotate(45deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 25px;
  margin-left: 25px;
  display: block;
  height: 50px;
  width: 50px;
  background-color: black;
} */