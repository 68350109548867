
.slide-navigation-container {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px;
  padding: 6px 0;
  border-top: 1px solid rgba(0,0,0,.125);
  font-family: 'Fira Mono', monospace;
}

.fade-enter .slide-navigation-container{
  transform: translateY(200px);
}

.fade-enter.fade-enter-active .slide-navigation-container{
  transform: translateY(0);
  transition-duration: 300ms;
  transition-delay: 100ms;
}

.speciem-navigation-item {
  display: flex;
  align-items: center;
  width: 60px;
  height: 40px;
  margin-right: 4px;
  border-radius: 0;
  color: grey;
}

.speciem-navigation-item.is-focused{
  opacity: 0.4;
}

.speciem-navigation-img {
  margin: auto;
  max-height: 100%;
}
