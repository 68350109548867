@import url(https://fonts.googleapis.com/css?family=Fira+Mono:400,500);
@font-face {
  font-family: reproNull;
  src: url(/static/media/Repro-null.256beb53.otf);
}
@font-face {
  font-family: repro100;
  src: url(/static/media/Repro-100.07636337.otf);
}
@font-face {
  font-family: repro200;
  src: url(/static/media/Repro-200.7cf09a18.otf);
}
@font-face {
  font-family: repro300;
  src: url(/static/media/Repro-300.d24e4674.otf);
}
@font-face {
  font-family: repro400;
  src: url(/static/media/Repro-400.599a7d65.otf);
}
@font-face {
  font-family: repro500;
  src: url(/static/media/Repro-500.2fefb9fd.otf);
}
@font-face {
  font-family: repro600;
  src: url(/static/media/Repro-600.644a9526.otf);
}

.repro-Null{
  font-family: reproNull;
}
.repro-100{
  font-family: repro100;
}
.repro-200{
  font-family: repro200;
}
.repro-300{
  font-family: repro300;
}
.repro-400{
  font-family: repro400;
}
.repro-500{
  font-family: repro500;
}
.repro-600{
  font-family: repro600;
}

.style-default {

}

.style-ss01 {
  -webkit-font-feature-settings: "ss01";
  font-feature-settings: "ss01";
}

.style-ss02 {
  -webkit-font-feature-settings: "ss02";
  font-feature-settings: "ss02";
}

body {
  font-family: myFirstFont;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: black;
}

* {
  box-sizing: border-box;
}
a{
  text-decoration: none;
}

.container {
  padding: 16px;
  max-width: 1400px;
  margin: 0px auto;
}

.about{
  border-bottom: 1px solid white;
}

.text{
  text-align: left;
  font-family: 'Fira Mono', monospace;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  color: white;
  font-weight: 400;
  font-size: 16px;
}

.text-small{
  margin: 40px 0 16px;
  font-size: 16px;
}

.text-small a{
  text-decoration: none;
  color: white;
}

.text-small a:hover{
  color:#636365;
}

.fontstyles-overview {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (min-width: 576px) {
  .text {
    font-size: 21px;
  }
  .text-small {
    font-size: 16px;
  }
}

@media (min-width: 768px){
  .container {
    padding: 24px;
  }
  .container:after{
    content: "";
    width: calc(33% - 16px);
  }
}

@media (min-width: 1281px) {
  .text {
    font-size: 24px;
  }
  .text-small {
    font-size: 21px;
  }
}
.letter {
  position: relative;
  text-align: center;
  background-color: white;
  margin: auto;
  font-size: 340px;
  line-height: 0.8;
}


@media (min-width: 576px) {
  .letter {
    font-size: 60vh;
    max-height: 55vh;
  }

  .fade-enter .slideshow-item .letter{
    font-size: 50vh;
  }

  .fade-enter.fade-enter-active .slideshow-item .letter{
    font-size: 60vh;
    -webkit-transition-duration: 300ms;
            transition-duration: 300ms;
  }
}


.letter-card {
  margin-top: 24px;
  font-size: 280px;
}

letter-card .badge{
  display: none;
}

.card {
  display: inline-block;
  height: 384px;
  width: 100%;
  margin: 0;
  margin-bottom: 32px;
  padding: 0;
  text-decoration: none;
  color: black;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.card-body {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 0;
}

.card-information {
  position: relative;
  padding-bottom: 12px;
  font-family: 'Fira Mono', monospace;
  border-bottom: 1px solid rgba(0,0,0,.125);
  padding: 14px;
  height: 48px;
}

@media (min-width: 576px) {
  .card  {
    width: calc(33% - 16px);
  }
}
.list-group {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  list-style-type: none;
  width: 100%;
  max-width: 500px;
}

.list-group-item{
  display: block;
  position: relative;
  height: 48px;
  padding: 14px;
  border-bottom: 1px solid rgba(0,0,0,.125);
  font-family: 'Fira Mono', monospace;
  color: black;
}

.list-group-item:after{
  content: "LADEN";
  position: absolute;
  top: 11px;
  right: 14px;
  padding: 4px 8px;
  background-color: #636365;
  color: white;
  font-size: 12px;
  border-radius: 16px;
  letter-spacing: 0.5px;
}

.list-group-item:hover{
  background-color:#eaebed;
}

.list-group-icon{
  float: right;
  color: #26a69a;
}
.overlay-page{
  background-color: white;
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  overflow: hidden;
  z-index: 100;
}

.overlay-page.fade-enter{
  border-radius: 8px;
  top:20px;
  bottom:20px;
  left:20px;
  right:20px;
}

@media (min-width: 576px) {
  .overlay-page.fade-enter{
    border-radius: 8px;
    top:80px;
    bottom:80px;
    left:80px;
    right:80px;
  }
}

.overlay-page.fade-enter.fade-enter-active{
  border-radius: 0;
  top:0;
  bottom:0;
  left:0;
  right:0;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.slideshow-item {
  width: 100%;
  height: 100%;
  margin-right: 10px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.slideshow {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: calc(100vh - 56px);
}

.overlay-enter .slideshow{
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.overlay-enter.overlay-entering .slideshow{
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
}
.letter-navigation-container {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px;
  padding: 6px 0;
  border-top: 1px solid rgba(0,0,0,.125);
  font-family: 'Fira Mono', monospace;
}

.fade-enter .letter-navigation-container{
  -webkit-transform: translateY(200px);
          transform: translateY(200px);
}

.fade-enter.fade-enter-active .letter-navigation-container{
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
  -webkit-transition-delay: 100ms;
          transition-delay: 100ms;
}

.letter-navigation-item {
  width: 25px;
  height: 40px;
  margin-right: 4px;
  border-radius: 0;
  display: flex;
  align-items: center;
  color: grey;
}

.letter-navigation-item.is-focused{
  color: black;
}

.letter-navigation-content {
  margin: auto;
  font-size: 26px
}
.button-close {
  position: absolute;
  top:12px;
  right: 12px;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  line-height: 32px;
  background-color: #636365;
  z-index: 20;
}

.button-close:before, .button-close:after {
  position: absolute;
  left: 15px;
  top: 8px;
  content: ' ';
  height: 16px;
  width: 2px;
  background-color: white;
}

.button-close:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.button-close:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

/*
.button-close:before {
  transform: rotate(-45deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 25px;
  margin-left: 25px;
  display: block;
  height: 50px;
  width: 50px;
  background-color: black;
}

.button-close:after {
  transform: rotate(45deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 25px;
  margin-left: 25px;
  display: block;
  height: 50px;
  width: 50px;
  background-color: black;
} */
.letter-showcase {
  width: 100%;
  height:100%;
}

.information {
  z-index: 10;
  position: absolute;
  padding: 12px 0;
  top: 12px;
  left: 12px;
  right: 12px;
  font-family: 'Fira Mono', monospace;
  color: black;
}

.badge {
  position: absolute;
  bottom: 64px;
  left:50%;
  margin-left: -28px;
  width:56px;
  padding: .25em .4em;
  font-family: 'Fira Mono', monospace;
  font-size: 16px;
  text-align: center;
  border-radius: .25rem;
  color: #fff;
  background-color: #636365;
  z-index: 20;
}

.badge:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #636365 transparent transparent transparent;
}

.badge.close{
  opacity: 0;
}

.badge.open{
  opacity: 1;
}

.fade-enter .badge{
  -webkit-transform: translateY(200px);
          transform: translateY(200px);
}

.fade-enter.fade-enter-active .badge{
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
  -webkit-transition-delay: 100ms;
          transition-delay: 100ms;
}

.slideshow-item {
  width: 100%;
  height: calc(100vh - 48px);
  margin-right: 10px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.slideshow-img {
  position: relative;
  margin: auto;
  max-height: 90%;
  max-width: 90%;
}


.fade-enter .slideshow-img{
  -webkit-transform: scale(0.2);
          transform: scale(0.2);
}

.fade-enter.fade-enter-active .slideshow-img{
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
}

.slide-navigation-container {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px;
  padding: 6px 0;
  border-top: 1px solid rgba(0,0,0,.125);
  font-family: 'Fira Mono', monospace;
}

.fade-enter .slide-navigation-container{
  -webkit-transform: translateY(200px);
          transform: translateY(200px);
}

.fade-enter.fade-enter-active .slide-navigation-container{
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
  -webkit-transition-delay: 100ms;
          transition-delay: 100ms;
}

.speciem-navigation-item {
  display: flex;
  align-items: center;
  width: 60px;
  height: 40px;
  margin-right: 4px;
  border-radius: 0;
  color: grey;
}

.speciem-navigation-item.is-focused{
  opacity: 0.4;
}

.speciem-navigation-img {
  margin: auto;
  max-height: 100%;
}

.speciem-showcase {
  width: 100%;
  height:100%;
}

.information {
  z-index: 10;
  position: absolute;
  padding: 12px 0;
  top: 12px;
  left: 12px;
  right: 12px;
  font-family: 'Fira Mono', monospace;
  color: black;
  /* border-bottom: 1px solid rgba(0,0,0,.125); */
}
