.card {
  display: inline-block;
  height: 384px;
  width: 100%;
  margin: 0;
  margin-bottom: 32px;
  padding: 0;
  text-decoration: none;
  color: black;
  overflow: hidden;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.card-body {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 0;
}

.card-information {
  position: relative;
  padding-bottom: 12px;
  font-family: 'Fira Mono', monospace;
  border-bottom: 1px solid rgba(0,0,0,.125);
  padding: 14px;
  height: 48px;
}

@media (min-width: 576px) {
  .card  {
    width: calc(33% - 16px);
  }
}