.slideshow-item {
  width: 100%;
  height: 100%;
  margin-right: 10px;
  background-color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.slideshow {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: calc(100vh - 56px);
}

.overlay-enter .slideshow{
  transform: translateY(0);
}

.overlay-enter.overlay-entering .slideshow{
  transform: translateY(0);
  transition-duration: 200ms;
}